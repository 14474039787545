

@import "vars";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "@ng-select/ng-select/themes/default.theme.css";

@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700');

:root {
  --bs-tooltip-color: #000000;
}

.tooltip-inner{
  font-size: 11px !important;
  color: #000000 !important;
}
body{
  font-family: 'PT Sans Narrow', sans-serif;
}

a{
  cursor: pointer;
}
mh-100 {
  min-height: 100vh !important;
}

html, body {
  height: 100%;
  min-height: 100vh;
}

pre{
  font-family: 'PT Sans Narrow', sans-serif;
}

.btn {
  text-transform: uppercase;

  &.btn-default {
    background-color: $gray-500;
    border-color: $gray-600;
    color: $white;
  }

  &.btn-warning {
    color: $white;
  }

  &:focus, &.focus{
    outline: none;
    box-shadow: none;
  }
}
.close:focus{
  outline: none;
    box-shadow: none;
}

.form-check-input.d-flex{
  margin-top: 0;
}

.content {
  position: relative;
}


.sidebar-container{
  background-color: $primary;
  color: $white;
}

.content-container{
  position: relative;
}
.content {
  //margin-bottom: 40px;
  min-height: calc(100vh - 123px);

  @media (max-width: 575px) {
    min-height: calc(100vh - 139px);
  }
}

.footer-container {
  //position: absolute;
  //bottom: 0;
  //width: 100%;
  //height: 40px; /* Set the fixed height of the footer here */
  //line-height: 40px; /* Vertically center the text there */
  //background-color: #f5f5f5;
}

router-outlet ~ * {
    width: 100%;
}

.icon-link{
  display: flex;
  align-items: center;
}
.icon {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  top: 2px;
  position: relative;

  &.icon-tiny {
    width: 1rem;
    height: 1rem;
  }

  &.icon-small {
    width: 1.3rem;
    height: 1.3rem;
  }

  &.icon-large {
    width: 3rem;
    height: 3rem;
  }


  &.icon-plus-grey {
    background-image: url('/assets/add_a_grey.png');
  }

  &.icon-plus-red {
    background-image: url('/assets/add_b_red.png');
  }

  &.icon-edit-grey {
    background-image: url('/assets/edit_a_grey.png');
  }

  &.icon-edit-red {
    background-image: url('/assets/edit_b_red.png');
  }

  &.icon-hide-red {
    background-image: url('/assets/hide_b_red.png');
  }

  &.icon-hide-grey {
    background-image: url('/assets/hide_a_grey.png');
  }

  &.icon-questionaire-red {
    background-image: url('/assets/questionaire_b_red.png');
  }

  &.icon-questionaire-red {
    background-image: url('/assets/questionaire_b_red.png');
  }

  &.icon-questionaire-action-red {
    background-image: url('/assets/questionaire_action_state_b_red.png');
  }

  &.icon-questionaire-grey {
    background-image: url('/assets/questionaire_a_grey.png');
  }

  &.icon-questionaire-green {
    background-image: url('/assets/questionaire_c_green.png');
  }

  &.icon-org-structure-red {
    background-image: url('/assets/org_structure_b_red.png');
  }

  &.icon-org-structure-grey {
    background-image: url('/assets/org_structure_a_grey.png');
  }

  &.icon-org-structure-green {
    background-image: url('/assets/org_structure_c_green.png');
  }

  &.icon-tick-red {
    background-image: url('/assets/tick_red.png');
  }

  &.icon-tick-white {
    background-image: url('/assets/tick_white.png');
  }

  &.icon-tick-green {
    background-image: url('/assets/tick_green.png');
  }

  &.icon-tick-grey {
    background-image: url('/assets/tick_grey.png');
  }

  &.icon-view-grey {
    background-image: url('/assets/view_a_grey.png');
  }

  &.icon-view-red {
    background-image: url('/assets/view_b_red.png');
  }

  &.icon-view-green {
    background-image: url('/assets/view_c_green.png');
  }

  

  &.icon-sort-grey {
    background-image: url('/assets/sort_a_grey.png');
  }

  &.icon-sort-red {
    background-image: url('/assets/sort_b_red.png');
  }

  &.icon-search-red {
    background-image: url('/assets/search_red.png');
  }
  
  &.icon-documents-grey {
    background-image: url('/assets/documents_a_red.png');
  }
  
  &.icon-documents-red {
    background-image: url('/assets/documents_b_red.png');
  }

  &.icon-documents-green {
    background-image: url('/assets/documents_c_green.png');
  }

  &.icon-umbrella-grey {
    background-image: url('/assets/umbrella_a_grey.png');
  }
  
  &.icon-umbrella-red {
    background-image: url('/assets/umbrella_b_red.png');
  }

  &.icon-umbrella-green {
    background-image: url('/assets/umbrella_c_green.png');
  }

  &.icon-user-red{
    background-image: url('/assets/user_management_b_red.png');
  }

  &.icon-user-grey{
    background-image: url('/assets/user_management_a_grey.png');
  }

  &.icon-elearning-red{
    background-image: url('/assets/elearning_module_b_red.png');
  }

  &.icon-user-management-red{
    background-image: url('/assets/top_user_management_b_red.png');
  }

  &.icon-document-management-red{
    background-image: url('/assets/document_management_b_red.png');
  }
  
  &.icon-acts-red{
    background-image: url('/assets/acts_b_red.png');
  }

  &.icon-notification-grey {
    background-image: url('/assets/notification_a_grey.png');
  }
  
  &.icon-notification-red {
    background-image: url('/assets/notification_b_red.png');
  }

  &.icon-compliance-program-red {
    background-image: url('/assets/Audits_Admin_Red.png');
  }

  &.icon-self-assessment-red {
    background-image: url('/assets/tlt_admin_edit_red.png');
  }


  &.icon-info-blue {
    background-image: url('/assets/icon_info_blue.png');
  }
  

  &.icon-library-red {
    background-image: url('/assets/top_edit_document_b_red.png');
  }


  &.icon-branded-red {
    background-image: url('/assets/stamp_red.png');
  }

  &.icon-trash-red {
    background-image: url('/assets/trash_red.png');
  }

  &.icon-stamp-grey{
    background-image: url('/assets/stamp_grey.png');
  }

  &.icon-merge-grey{
    background-image: url('/assets/merge_grey.png');
  }

  &.icon-download-grey{
    background-image: url('/assets/download_grey.png');
  }

  &.icon-download-red{
    background-image: url('/assets/download_red.png');
  }

  &.icon-edit-grey{
    background-image: url('/assets/edit_grey.png');
  }

  &.icon-upload-grey{
    background-image: url('/assets/upload_grey.png');
  }

  &.icon-version-grey{
    background-image: url('/assets/verions_grey.png');
  }

  &.icon-status-grey{
    background-image: url('/assets/status_grey.png');
  }

  &.icon-tick-green{
    background-image: url('/assets/tick_green.png');
  }
  

  &.icon-documents-grey{
    background-image: url('/assets/documents_a_grey.png');
  }

  &.icon-email-red{
    background-image: url('/assets/email_red.png');
  }
  
  &.icon-email-grey{
    background-image: url('/assets/email_grey.png');
  }
  &.icon-left-arrow-grey{
    background-image: url('/assets/added_icon_left_arrow.png');
  }
  
  &.icon-period-red{
    background-image: url('/assets/period_b_red.png');
  }

}


.org-list{
    background-color: $white;
    
}
.company-list{
    background-color: $white;
    
}

.open.dropdown .dropdown-menu { display: block; transform: none; }

.ui-select-match .btn.btn-default{
  color: $body-color;
}


.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
  background-color: $gray-500;
  border-color: $gray-600;
  color: $white;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    background-color: $gray-600;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{
  border-right-color: $gray-600;
}

.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder, .ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value{
  color: $body-color;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
  color: $body-color;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color:$gray-400;
    color: $body-color;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected{
  background-color:$gray-200;
    color: $body-color;
}

.ngx-loading-logo{
		animation: rotation 2s infinite linear;
}

.ck-editor__editable {
    min-height: 300px;
}

@keyframes rotation {
		from {
				-webkit-transform: rotate(0deg);
		}
		to {
				-webkit-transform: rotate(359deg);
		}
}

.custom-file-input.ng-invalid.ng-dirty ~ .custom-file-label{
  border: 1px solid $red;
}

ng-select.is-invalid .ng-select-container{
    border-color: $red !important;
}

ng-select.is-valid .ng-select-container{
    border-color: $green !important;
}

.ng-select.ng-select-disabled>.ng-select-container{
  background-color: var(--bs-body-bg);
}

.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder, .ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value{
  color: #999;
}


.text-green{
  color: $green;
}
.text-red{
  color: $red;
}
.text-blue{
  color: $blue;
}
.pen-red{
color: $red !important;
background-color: transparent;
}
.pen-green{
  color: $green !important;
  background-color: transparent;
}
.pen-blue{
  color: $blue !important;
  background-color: transparent;
}
.pen-orange{
  color: $orange !important;
  background-color: transparent;
}
.pen-yellow{
  color: $yellow !important;
  background-color: transparent;
}



.fullscreenModal{
  &>.modal-dialog {
    max-width: 100%;
    width: 100% !important;
    height: 100% !important;
    margin: 0;
    padding: 0;

    &>.modal-content {
      height: auto !important;
      min-height: 100% !important;
      border-radius: 0;
    }
  }
}

.form-group{
  margin-bottom: 1rem;
}

.form-control{
  background-color: white;
}

.table.bg-white > :not(caption)  {
  
  a, strong, div, span, em{
    background-color: transparent;
  }
}

.table{
  td{
    color: $primary;
    vertical-align: top;
    &>span{
      display: block;
    }
  }
}

label.is-invalid{
  color: $red;
}

.strike{
  text-decoration: line-through;
}