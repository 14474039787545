$body-bg: #f4f4f4;
$body-color: #40536c;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #09478a;
$green: #2a8d15;
$red: #851f22;
$orange: #cf5d1f;
$yellow: #baad26;

$primary: #40536c;
$success: #2b8d15;
$danger: #851f22;


$alt-blue: #00b0f0;
$alt-green: #00b050;
$alt-red: #ff0000;
$alt-dark-red: #c00000;
$alt-orange: #ffc000;
$alt-yellow: #ffff00;

$link-decoration: none;
$link-hover-decoration: underline;

$table-cell-padding-y:        .5rem;
$table-cell-padding-x:        .5rem;

$table-color: $primary